<template>

      <div style="padding: 0 10px;">
		   <div v-for="(item,index) in list" :key="index">
			   <div class="content" @click="getDetail(item.id)">
			   				 <div style="text-align: left;">
			   					 <div>编号:<div style="font-size: 12px;display: inline-block;">{{item.num}}</div></div>
			   					 <div>顾客名称:<div style="font-size: 12px;display: inline-block;">{{item.cust_name}}</div></div>
			   				 </div>
			   				 <div>创建时间:<div style="font-size: 12px;display: inline-block;">{{item.createtime}}</div></div>
			   </div>
		   </div>
	  </div>
</template>

<script>
    import { messageList, messageDetail } from '@/api/modelTrain/user'
    export default {
        data() {
            return {
                list:'',
            }
        },
        watch:{

        },
        computed: {

        },
        created() {
          
        },
		mounted() {
			this.getData()
		},
        methods: {
            getData(){
				 messageList().then(res=>{
					  if(res.code==1){
						 this.list=res.data  
						 console.log(this.list)
						 this.list.forEach((item,index)=>{
							 item.createtime=this.getLocalTime(item.createtime)
						 })
						 
					  }
				 })
			},
			getLocalTime(nS) {     
			   return new Date(parseInt(nS) * 1000).toLocaleString().replace(/:\d{1,2}$/,' ');     
			},
			getDetail(id){
				this.$router.push({name: 'detail',params: {msId:id,}
				   })
			}
        }
    }
</script>

<style scoped lang="scss">
   #selectForm >>> .el-form-item__label {
     font-size: 12px;
   }
   .content{
	       display: flex;
	       font-size: 13px;
	       border: 1px solid;
	       padding: 10px 5px;
	       justify-content: space-between;
	       align-items: center;
		   margin: 10px 0px;
   }
</style>